import Sebrae from '../assets/imgs/sebrae.png';

export const BcxSectionD = () => {
    return (
        <section id="sectionD">
            <div className="container">
                <div className="header">
                    <div className="titulo">
                        <img src={Sebrae} alt="Logo da Sebrae" />
                        <h2>
                            Uma Parceria com <br />
                            <strong>soluções para sua empresa</strong>
                        </h2>
                    </div>
                    <div className="descricao">
                        <p>
                            O Sebrae está oferecendo 2 capacitações online GRATUITAS e 1
                            consultoria online GRATUITA aos empreendedores interessados.
                        </p>
                        <p>
                            Mesmo sem a efetivação do crédito, você pode ter acesso a
                            ferramentas que te ajudam a gerenciar melhor seus negócios.
                        </p>
                        <p>
                            <strong>Aproveite essa oportunidade!</strong>
                        </p>
                    </div>
                </div>

                <div className="content">
                    <div className="col">
                        <h3>
                            Curso venda online com marketing raiz: WhatsApp e Instagram para lucrar mais
                            <br />
                            (Temática: Inovação, Marketing e Vendas)
                            <br />
                            (Modalidade: Online)
                        </h3>
                        <a
                            className="btn"
                            href="https://digital.sebraers.com.br/produtos/marketing-e-vendas/curso/venda-online-com-marketing-raiz-whatsapp-e-instagram-para-lucrar-mais/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Acesse aqui
                        </a>

                        <h3>
                            Curso planejamento para fazer acontecer
                            <br />
                            (Temática: Estratégia)
                            <br />
                            (Modalidade: Online)
                        </h3>
                        <a
                            className="btn"
                            href="https://digital.sebraers.com.br/produtos/estrategia/curso/planejamento-para-fazer-acontecer/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Acesse aqui
                        </a>

                        <h3>
                            Tendência no varejo: Ideias inovadoras para o teu negócio
                            <br />
                            (Temática: Mercado)
                            <br />
                            (Modalidade: Online)
                        </h3>
                        <a
                            className="btn"
                            href="https://digital.sebraers.com.br/produtos/mercado/curso/tendencias-no-varejo-ideias-inovadoras-para-o-teu-negocio/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Acesse aqui
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}

