export const BcxSectionG = () => {
    return (
        <section id="sectionG">
            <div className="container">
                <h2 className="title">
                    <strong>Dúvidas Frequentes</strong> <br />
                    Sobre o Programa
                </h2>

                <div className="duvida">
                    <h3>
                        Sou associado, posso ir até a minha agência solicitar o crédito?
                    </h3>
                    <p>
                        As demandas serão recebidas através do formulário nesta página.
                        <br />O Sicredi ou a RSGaranti farão a análise prévia e retornarão o
                        contato.
                    </p>
                </div>

                <div className="duvida">
                    <h3>A garantidora irá cobrar alguma comissão?</h3>
                    <p>
                        Sim. Para saber mais, entre em contato com a RSGaranti através do
                        telefone (54) 3021-0400.
                    </p>
                </div>

                <div className="duvida">
                    <h3>
                        Tenho dívidas com tributos municipais, posso acessar o crédito?
                    </h3>
                    <p>
                        O crédito também pode ser usado para fazer este tipo de
                        regularização,
                        <br />
                        mas para isso será necessário procurar a prefeitura antes de
                        solicitar o crédito.
                    </p>
                </div>

                <div className="duvida">
                    <h3>Serão verificados restritivos?</h3>
                    <p>
                        Sim, a análise de crédito será feita normalmente junto a Sicredi e a
                        RSGaranti.
                    </p>
                </div>

                <div className="duvida">
                    <h3>
                        Para ter acesso ao crédito, preciso abrir uma conta com a Sicredi
                        Caminho das Águas?
                    </h3>
                    <p>
                        Sim. Se você ainda não é associado à cooperativa, será necessário
                        fazer a abertura de uma conta para ter acesso aos benefícios do
                        programa.
                    </p>
                </div>

                <h4>
                    Para mais informações, entre em contato com a Sala do Empreendedor
                    através do <br />
                    (51) 3627-8535 Ramal-535.
                </h4>
            </div>
        </section>
    )
}
