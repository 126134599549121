import Aviso from '../assets/imgs/ico-8.png'
import microempreendedor from "../assets/imgs/microempreendedor-individual-e-pescador.jpg";
import microempresa from "../assets/imgs/microempresa.jpg";
import pequenas_empresas from "../assets/imgs/pequenas-empresas.jpg";

export const BcxSectionE = () => {
    return (
        <section id="sectionE">
            <div className="container">
                <div className="micInd rowCard">
                    <div className="imagem">
                        <img src={microempreendedor} alt="Microempreendedor individual e pescador" />
                    </div>

                    <div className="texto">
                        <h3>
                            <strong>
                                Microempreendedor
                                <br />
                                Individual e Pescador
                            </strong>
                        </h3>
                        <p>
                            Com receita bruta anual igual <br /> ou inferior a R$ 81 mil.
                        </p>
                        <p>
                            <strong>Limite de crédito:</strong>
                            <br />
                            R$ 15.000,00 em 36 parcelas (sem carência) de até R$ 541,83.
                            <br />
                            R$ 15.000,00 em 36 parcelas (02 meses de carência) de até R$ 583,67.
                            <br />
                            Custo de garantia: R$ 993,60.
                        </p>

                        <p></p>
                    </div>
                </div>

                <div className="micEmp rowCard">
                    <div className="imagem">
                        <img src={microempresa} alt="Micro empresa" />
                    </div>

                    <div className="texto">
                        <h3>
                            <strong>Microempresa</strong>
                        </h3>
                        <p>
                            Com receita bruta anual igual <br /> ou inferior a R$ 360 mil.
                        </p>
                        <p>
                            <strong>Limite de crédito:</strong>
                            <br />
                            Limite de crédito: R$ 100.000,00 em 36 parcelas (sem carência) de até R$ 3.612,21.
                            <br />
                            Limite de crédito: R$ 100.000,00 EM 36 parcelas (com e meses de carência) de até R$ 3.891,13.
                            <br />
                            Custo da garantia: R$ 6.624,00.
                        </p>
                    </div>
                </div>

                <div className="peqEmp rowCard">
                    <div className="imagem">
                        <img src={pequenas_empresas} alt="Pequenas empresas" />
                    </div>

                    <div className="texto">
                        <h3>
                            <strong>
                                Pequenas
                                <br />
                                Empresas
                            </strong>
                        </h3>
                        <p>Faturamento anual igual <br /> ou inferior a R$ 4,8 milhões.</p>
                        <p>
                            <strong>Limite de crédito:</strong>
                            <br />
                            R$ 150.000,00 em 36 parcelas (sem carência) de até R$ 5.418,32.
                            <br />
                            R$ 150.000,00 em 36 parcelas (com 2 meses de carência) de até R$ 5.836,69.
                            <br />
                            Custo de garantia: R$ 9.936,00.
                        </p>
                    </div>
                </div>

                <div className="aviso">
                    <div className="imgAviso">
                        <img
                            src={Aviso}
                            alt="Icone, formato Balão, com Exclamação dentro"
                        />
                    </div>

                    <div className="textoAviso">
                        <p>
                            • Sujeito a análise de crédito. Parcelas simuladas sem IOF.
                            <br />• Terão acesso a linha associados que apresentarem no mínimo
                            de <strong>01 ano de faturamento completo,</strong> declarados
                            nos documentos indicados e necessários abaixo.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}
