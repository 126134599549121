import imbecred_logo from '../assets/imgs/main_imbecred_logo.png';
import parceiros from '../assets/imgs/parceiros.png';

export const BcxSectionA = () => {
    return (
        <section id="sectionA">
            <div className="container">
                <div className='text'>
                    <p>Imbé está localizado no litoral do Rio Grande do Sul. Seu núcleo populacional nasceu a partir da povoação de pescadores que viviam às margens do Rio Tramandaí, ainda no século XVIII.   O Município possui 11 Km de praias e balneários que são muito procurados por suas belezas naturais.</p>
                    <p>O Imbécred é uma iniciativa inovadora no litoral do Rio Grande do Sul. O Microcrédito nasce da parceria entre a Prefeitura de Imbé, o Sebrae, Sicredi Caminho da Águas, como a cooperativa de crédito, com aval da RS Garanti. </p>
                    <p>O objetivo do Programa de Crédito Assistido é impulsionar o desenvolvimento da região costeira e, ao mesmo tempo, oferecer um importante suporte aos pescadores locais.</p>
                    <p>Através do Imbécred, os pescadores locais, e empreendedores, têm acesso a recursos e treinamentos que os capacitam a melhorar suas práticas, preservar os recursos marinhos e, ao mesmo tempo, aumentar sua renda. Além disso, essa aliança está fomentando o empreendedorismo local, incentivando o surgimento de novos negócios que complementam a atividade pesqueira.</p>
                    <p>A união entre a Prefeitura de Imbé, Sebrae, Sicredi e RSGaranti é um exemplo brilhante de como a colaboração pode criar um impacto positivo profundo nas comunidades e no desenvolvimento regional. O Imbécred não é apenas uma parceria; é uma promessa de um futuro mais brilhante para todos os envolvidos e para a região de Imbé como um todo.</p>

                </div>
                <div className="logos">
                    <img
                        src={imbecred_logo}
                        alt="Logotipo da Imbécred"
                    />
                    <img
                        src={parceiros}
                        alt="Logotipos Imbé, RS Garanti, Sebrae, Sicredi"
                    />
                </div>
            </div>
        </section>
    )
}