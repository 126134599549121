import imbe from '../assets/imgs/imbe.jpg';
import rsgaranti from '../assets/imgs/rsgaranti.png';
import sicredi from '../assets/imgs/sicredi.png';
import sebrae from '../assets/imgs/sebrae.jpg';

export const Footer = () => {
    return (
        <footer>
            <a href='https://www.imbe.rs.gov.br/?titulo=Sala%20do%20Empreendedor&template=conteudo&categoria=10924&codigoCategoria=10924' target='_blank' rel='noopener noreferrer'>
                <img src={imbe} alt="Logo da Imbé" />
            </a>
            <a href='https://www.rsgaranti.org.br/programas_credito.php' target='_blank' rel='noopener noreferrer'>
            <img src={rsgaranti} alt="Logo do RSGaranti" />
            </a>
            <a href='https://www.sicredi.com.br/coop/caminhodasaguasrs/' target='_blank' rel='noopener noreferrer'>
                <img src={sicredi} alt="Logo da Sicredi" />
            </a>
            <a href='https://sebraers.com.br/' target='_blank' rel='noopener noreferrer'>
            <img src={sebrae} alt="Logo do Sebrae" />
            </a>
        </footer>
    )
}

